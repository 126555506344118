var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AdminModeratorAPI, NEW_URL } from "../../../api/API";
import { useAppDispatch } from "../../../redux/store";
import style from "./CardRightSetOnMainPage.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../../constants";
import unpin from "../../../assets/img/unpin_for_move_publication_top.svg";
import { useMutation, useQueryClient } from "react-query";
import { setActive, setErrorMessageToUserForModal, setMessageToUserForModal, setModal, } from "../../Card/cardImports";
import { changeNeedToReloadAdsBlock } from "../../../redux/reducers/AdminModeratorReducer/AdminAdsModeration";
export var RightSetBlockOnMainPage = function () {
    var isAdmin = useSelector(function (state) { return state.auth.userAuthInfo.is_superuser; });
    var data = useSelector(function (state) { return state.rightSet.rightSet; });
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var queryClient = useQueryClient();
    var addVacancyToAdsBlockMutation = useMutation({
        mutationFn: function (param) { return AdminModeratorAPI.addOrDeleteVacancyFromAdsList(param.id); },
        onSuccess: function (res) {
            Promise.all([
                queryClient.invalidateQueries("fetchVacanciesOnVacancyPage"),
                queryClient.invalidateQueries("fetchVacanciesInAdminAdsPanel"),
            ]);
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate add to draft or from", err);
        },
    });
    var addPublicationToAdsBlockMutation = useMutation({
        mutationFn: function (param) { return AdminModeratorAPI.addOrDeletePublicationFromAdsList(param.id); },
        onSuccess: function (res) {
            Promise.all([
                queryClient.invalidateQueries("fetchPublicationOnMainPage"),
                queryClient.invalidateQueries("fetchPublicationsOnCompanyPage"),
                queryClient.invalidateQueries("PublicationsInProfile"),
                queryClient.invalidateQueries("fetchPublicationInAdminAdsPanel"),
            ]);
            dispatch(changeNeedToReloadAdsBlock());
            dispatch(setMessageToUserForModal(res.data));
            dispatch(setModal("success-alert-modal"));
            dispatch(setActive(true));
        },
        onError: function (err) {
            var _a;
            if (err) {
                dispatch(setErrorMessageToUserForModal((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.data));
                dispatch(setModal("error-alert-modal"));
                dispatch(setActive(true));
            }
            console.log("error invalidate add to draft or from", err);
        },
    });
    var handleNavigateToPost = function (url, id) {
        if (url) {
            return "/".concat(url).concat(id, "/");
        }
        else {
            return "#";
        }
    };
    var handleAddOrRemoveFromAdsBlock = function (id, typePost) {
        switch (typePost) {
            case "vacancy/":
                addVacancyToAdsBlockMutation.mutate({ id: id });
                dispatch(changeNeedToReloadAdsBlock());
                break;
            case "news/":
                addPublicationToAdsBlockMutation.mutate({ id: id });
                dispatch(changeNeedToReloadAdsBlock());
                break;
        }
    };
    return (_jsx(_Fragment, { children: data &&
            (data === null || data === void 0 ? void 0 : data.map(function (item, index) { return (_jsx("div", __assign({ className: style.RightSetOnMainPage }, { children: _jsxs("div", __assign({ className: style.content }, { children: [_jsxs("div", __assign({ className: style.title_wrapper }, { children: [_jsx("span", __assign({ className: style.title, onClick: function () {
                                        if (item.type === "Вакансии") {
                                            navigate(PATH.vacancy);
                                        }
                                    } }, { children: item.type })), _jsx("img", { src: "".concat(NEW_URL).concat(item.icon), className: style.icon })] })), _jsx("div", __assign({ className: style.events }, { children: (item === null || item === void 0 ? void 0 : item.info) &&
                                item.info.map(function (rowContent, index) { return (_jsxs("div", __assign({ className: style.linkAndPinWrapper }, { children: [_jsx(Link, __assign({ to: handleNavigateToPost(rowContent === null || rowContent === void 0 ? void 0 : rowContent.url, rowContent === null || rowContent === void 0 ? void 0 : rowContent.id_full), className: "".concat(style.rowContent, " ").concat(isAdmin ? style.rowContentAdmin : ''), style: { borderBottom: "none" } }, { children: _jsx("span", __assign({ className: style.rowContentTitle }, { children: rowContent === null || rowContent === void 0 ? void 0 : rowContent.title })) }), index), isAdmin ? (_jsx("img", { onClick: function () {
                                                handleAddOrRemoveFromAdsBlock(rowContent.id, rowContent.url);
                                            }, className: style.unpinIcon, src: unpin, alt: "unpin" })) : null] }))); }) }))] })) }), index)); })) }));
};
