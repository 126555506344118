var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import style from "./noPostYet.module.css";
import img_no_post from "../../assets/img/comsonaut_404.png";
export function NoPostYetCardForProfileView(_a) {
    var text = _a.text;
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: style.card }, { children: _jsxs("div", __assign({ className: style.card_container }, { children: [_jsxs("h2", __assign({ className: style.text }, { children: [" ", text ? text : ""] })), _jsx("div", __assign({ className: style.imgWrapper }, { children: _jsx("img", { src: img_no_post, className: style.noPostImg }) }))] })) })) }));
}
